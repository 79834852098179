<template>
  <Layout>
    <div class="rwt-portfolio-details rn-section-gap">
      <div class="container">
        <div class="row">
          <!--<div class="col-lg-10 offset-lg-1">-->
          <div class="col-lg-12">
            <div class="inner">
              <div class="details-list">
                <div class="row row--30">
                  <div class="col-lg-6">
                    <ImageAlbum v-if="product.albums" :images="product.albums"/>
                  </div>
                  <div class="col-lg-6 mt_md--30 mt_sm--30">
                    <div class="content-left">
                      <h4 class="title">{{ product.title }}</h4>
                      <div class="single-list-wrapper">
                        <div v-for="item in product.properties" :key="item.name" class="single-list">
                          <label>{{ item.name }}:</label>
                          <span>{{ item.value }}</span>
                        </div>
                      </div>
                      <div class="button-group mt--20" data-aos="fade-up" data-aos-delay="90">
                          <Button title="Buy It" size="large" icon="arrow-right" :is-link="false" @btnClick="gotoPay"/>

                        <!--<Button title="Buy On Amazon" size="large" icon="arrow-right" :is-link="false"
                                @btnClick="open(product.amazonUrl)"/>-->
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mt--40 row--30">
                  <h4 class="title">Tips: </h4>
                  <ul>
                    <li v-for="tip of product.tips">{{ tip }}</li>
                  </ul>
                </div>
              </div>
              <div class="portfolio-gallery mt--40">
                <div v-for="(content, index) of product.contents" :key="`content${index}`" class="gallery mt--30">
                  <div v-if="content.type === '图片'">
                    <div v-for="item of content.list" class="thumbnail">
                      <img class="radius w-100" :src="item"
                           alt="Corporate Image"/>
                    </div>
                  </div>
                  <div v-if="content.type === '视频'">
                    <iframe v-for="item of content.list" width="560" height="430" :src="item"
                            title="YouTube video player" frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen></iframe>
                  </div>
                  <div v-if="content.type === '三等分图片块' || content.type === '四等分图片块'" class="row mt--30">
                    <div v-for="item of content.list" :class="content.type === '三等分图片块' ? 'col-4' : 'cols-3'">
                      <div>
                        <img class="radius w-100"
                             :src="item.image"/>
                      </div>
                      <h5 class="mt-4">{{ item.title }}</h5>
                      <p>{{ item.text }}</p>
                    </div>
                  </div>
                  <div v-if="content.type === '文本块'" class="row mt--30">
                    <div v-for="item of content.list">
                      <h4 v-if="item.title">
                        {{ item.title }}
                      </h4>
                      <h5 v-if="item.subtitle">
                        {{ item.subtitle }}
                      </h5>
                      <ul v-if="item.message">
                        <li>{{ item.message }}</li>
                      </ul>
                    </div>
                  </div>
                  <div v-if="content.type === '文本块 - 多子项'" class="row mt--30">
                    <h4 v-if="content.title">
                      {{ content.title }}
                    </h4>
                    <h5 v-if="content.subtitle">
                      {{ content.subtitle }}
                    </h5>
                    <ul>
                      <li v-for="message of content.messages">{{ message }}</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from '../components/common/Layout'
import productMixin from '@/mixins/ProductMixin'
import ImageAlbum from '@/components/elements/imageAlbum/ImageAlbum'
import Button from '@/components/elements/button/Button'

export default {
  name: 'ProductDetailPage',
  components: {ImageAlbum, Layout, Button},
  data() {
    return {
      asin: this.$route.params.asin,
      product: {},
    }
  },
  methods: {
    getData() {
      this.$axios.get('/api/website/product/' + this.asin)
          .then(res => {
            console.log(res)
            res.albums = JSON.parse(res.albums)
            res.properties = JSON.parse(res.properties)
            res.tips = JSON.parse(res.tips)
            res.contents = JSON.parse(res.contents)
            this.product = res
          })
    },
    open(url) {
      window.open(url, '_blank')
    },
    gotoPay() {
      this.$router.push('/product/detail/BBJ-01/pay')
    }
  },
  mounted() {
    // this.getProduct(this.id);
    this.getData()
  },
}
</script>