<script>
import {defineComponent} from 'vue'
import Layout from '@/components/common/Layout.vue'
import SectionTitle from '@/components/elements/sectionTitle/SectionTitle.vue'
import Button from '@/components/elements/button/Button.vue'

export default defineComponent({
  name: 'PayPage',
  components: {Button, SectionTitle, Layout},
  data() {
    return {
      showResult: false,
      result: '',
      order: {
        siteName: "MirrorSafe",
        firstName: null,
        lastName: null,
        email: null,
        phone: null,
        streetAddress: null,
        postcode: null,
        state: null,
        city: null,
        cardNumber: null,
        cardHolder: null,
        expiration: null,
        cvc: null,
      },
    }
  },
  methods: {
    save() {
      this.showResult = false
      this.result = ''


      if (!this.order.firstName) {
        this.result = 'First Name is required'
        this.showResult = true
        return
      }

      if (!this.order.lastName) {
        this.result = 'Last Name is required'
        this.showResult = true
        return
      }

      if (!this.order.email) {
        this.result = 'Email is required'
        this.showResult = true
        return
      }

      if (!/^[\\.a-zA-Z0-9_-]+[@＠][a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/.test(this.order.email) || this.order.email.length > 200) {
        this.result = `Email is invalid.`
        this.showResult = true
        return
      }

      if (!this.order.phone) {
        this.result = 'Phone is required'
        this.showResult = true
        return
      }

      if (!this.order.streetAddress) {
        this.result = 'Street Address is required'
        this.showResult = true
        return
      }

      if (!this.order.postcode) {
        this.result = 'Postcode is required'
        this.showResult = true
        return
      }

      if (!this.order.state) {
        this.result = 'State is required'
        this.showResult = true
        return
      }

      if (!this.order.city) {
        this.result = 'City is required'
        this.showResult = true
        return
      }

      if (!this.order.cardNumber) {
        this.result = 'Card Number is required'
        this.showResult = true
        return
      }

      if (!this.order.cardHolder) {
        this.result = 'Card Holder is required'
        this.showResult = true
        return
      }

      if (!this.order.expiration) {
        this.result = 'Expiration is required'
        this.showResult = true
        return
      }

      if (!this.order.cvc) {
        this.result = 'CVC is required'
        this.showResult = true
        return
      }

      this.$axios.post('/api/website/pay', this.order).then(() => {
      });

      this.$router.replace('/pay/success')
    },
  },
})
</script>

<template>

  <Layout>

    <div class="rwt-portfolio-details rn-section-gap">
      <div class="container">
        <form ref="form" class="contact-form-1">
          <div class="row">
            <div class="col-lg-12 mb--40">
              <SectionTitle
                  text-align="center"
                  subtitle="Pay Form"
                  title="Billing address"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <label>First Name: </label>
              <div class="form-group">
                <input
                    type="text"
                    name="firstName"
                    placeholder="Tom"
                    required
                    v-model="order.firstName"
                />
              </div>
            </div>
            <div class="col-md-6">
              <label>Last Name: </label>
              <div class="form-group">
                <input
                    type="text"
                    name="lastName"
                    placeholder="Thomas"
                    required
                    v-model="order.lastName"
                />
              </div>
            </div>
            <div class="col-md-6">
              <label>Email Address: </label>
              <div class="form-group">
                <input
                    type="text"
                    name="email"
                    placeholder="thomas@gmail.com"
                    required
                    v-model="order.email"
                />
              </div>
            </div>
            <div class="col-md-6">
              <label>Phone: </label>
              <div class="form-group">
                <input
                    type="text"
                    name="phone"
                    placeholder="+1 (123) 456-7890"
                    required
                    v-model="order.phone"
                />
              </div>
            </div>
            <div class="col-md-8">
              <label>Street address: </label>
              <div class="form-group">
                <input
                    type="text"
                    name="streetAddress"
                    placeholder="12345 SW Kittiwake Ct"
                    required
                    v-model="order.streetAddress"
                />
              </div>
            </div>
            <div class="col-md-4">
              <label>Postcode/Zip: </label>
              <div class="form-group">
                <input
                    type="text"
                    name="postcode"
                    placeholder="97007"
                    required
                    v-model="order.postcode"
                />
              </div>
            </div>
            <div class="col-md-6">
              <label>State: </label>
              <div class="form-group">
                <input
                    type="text"
                    name="state"
                    placeholder="OR"
                    required
                    v-model="order.state"
                />
              </div>
            </div>
            <div class="col-md-6">
              <label>City: </label>
              <div class="form-group">
                <input
                    type="text"
                    name="city"
                    placeholder="Beaverton"
                    required
                    v-model="order.city"
                />
              </div>
            </div>
          </div>
          <div class="row mt--30">
            <div class="col-lg-12 mb--40">
              <SectionTitle
                  text-align="center"
                  title="Payment Method"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <label>Card Number: </label>
              <div class="form-group">
                <input
                    type="text"
                    name="cardNumber"
                    placeholder="**** **** **** ***"
                    required
                    v-model="order.cardNumber"
                />
              </div>
            </div>
            <div class="col-md-8">
              <label>Card Holder: </label>
              <div class="form-group">
                <input
                    type="text"
                    name="cardHolder"
                    placeholder="Tom Thomas"
                    required
                    v-model="order.cardHolder"
                />
              </div>
            </div>
            <div class="col-md-2">
              <label>Expiration: </label>
              <div class="form-group">
                <input
                    type="text"
                    name="expiration"
                    placeholder="MM/YY"
                    required
                    v-model="order.expiration"
                />
              </div>
            </div>
            <div class="col-md-2">
              <label>CVC: </label>
              <div class="form-group">
                <input
                    type="text"
                    name="userName"
                    placeholder="***"
                    required
                    v-model="order.cvc"
                />
              </div>
            </div>
          </div>
          <div class="col-md-12">
            <div class="form-group">
              <Button class="btn-default btn-large" @btnClick="save" size="large" icon="arrow-right" :is-link="false"
                      title="Order Now"/>
            </div>
          </div>
          <div class="form-group" style="color: red" v-html="showResult ? result : null">
          </div>
        </form>
      </div>
    </div>
  </Layout>
</template>

<style scoped>

</style>