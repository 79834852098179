<script>
import {defineComponent} from 'vue'
import Layout from '@/components/common/Layout.vue'
import SectionTitle from '@/components/elements/sectionTitle/SectionTitle.vue'

export default defineComponent({
  name: 'PayFinishedPage',
  components: {SectionTitle, Layout},
})
</script>

<template>
  <layout>
    <div class="rwt-portfolio-details rn-section-gap">
      <div class="container">
        <div class="row">
          <div class="col-lg-12 mb--40">
            <SectionTitle
                text-align="center"
                subtitle="Purchase Successful!"
                title="Thank you for your purchase! We appreciate your business and hope you enjoy your new item."
            />
          </div>
        </div>
      </div>
    </div>
  </layout>
</template>

<style scoped>

</style>